import "@hotwired/turbo-rails"
import { application } from "./controllers/application"
require("./src/taos")

// Only include the specific javascript used on the client websites
// Lightbox and Nav

import CarouselController from "./controllers/carousel_controller.js"
application.register("carousel", CarouselController)

import LightboxController from "./controllers/lightbox_controller.js"
application.register("lightbox", LightboxController)

import NavController from "./controllers/nav_controller.js"
application.register("nav", NavController)
